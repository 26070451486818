import request from "./fetch";

export const login = (data) => request.post("login", data);

export const getAllJob = (uid, token) =>
  request.get(`jobFilterFromUsers/${uid}`, token);
// request.get(`jobs`, token);
// export const getAllJob = (uid, token) =>

export const getBoundingboxSolutionByJobId = (jobId, token) =>
  request.get("ImageBoundingBoxSolutions/" + jobId, token);

export const getQCBoundingboxSolutionByJobId = (jobId, token) =>
  request.get("qc-image-bounding-box/" + jobId, token);

export const patchBoundingboxSolutionByJobId = (jobId, data, token) =>
  request.patch("image-Boundingbox-solutions/" + jobId, data, token);

export const patchQCBoundingboxSolutionByJobId = (jobId, data, token) =>
  request.patch("qc-image-bounding-box/" + jobId, data, token);

export const patchQCEditBoundingboxSolutionByJobId = (jobId, data, token) =>
  request.patch("qc-edit-image-bounding-box/" + jobId, data, token);

export const getPolygonSolutionByJobId = (jobId, token) =>
  request.get("ImagePolygonolutions/" + jobId, token);

export const getQCPolygonSolutionByJobId = (jobId, token) =>
  request.get("qc-image-Polygon/" + jobId, token);

export const patchPolygonSolutionByJobId = (jobId, data, token) =>
  request.patch("image-polygon/" + jobId, data, token);

export const patchQCPolygonSolutionByJobId = (jobId, data, token) =>
  request.patch("qc-image-Polygon/" + jobId, data, token);

export const patchQCEditPolygonSolutionByJobId = (jobId, data, token) =>
  request.patch("qc-edit-image-Polygon/" + jobId, data, token);

export const changeJobStatus = (data, token) =>
  request.post("changeStatus", data, token);


import React from "react";
import { useLocalStorage } from "../utils/helper";

const initialState = {
	jobData: undefined,
	isQC:false,
};

export const JobContext = React.createContext();

const JobReducer = (prevState, action) => {
	switch (action.type) {
		case "SET_JOB_DATA":
			return {
				...prevState,
				jobData: action.jobData,
			};
		case "SET_IS_QC":
			return {
				...prevState,
				isQC: action.isQC,
			};
		case "RESET":
			return {
				jobData: undefined,
				isQC:false
			};
		default:
			return {
				...prevState,
			};
	}
};

export const JobProvider = (props) => {
	const [jobsData, setJobsData] = useLocalStorage(
		"jobsData",
		JSON.stringify(initialState)
	);

	const [state, dispatch] = React.useReducer(JobReducer, JSON.parse(jobsData));
	React.useEffect(() => {
		setJobsData(JSON.stringify(state));
	}, [state, setJobsData]);

	const value = React.useMemo(
		() => ({
			...state,
			setJobData:  (data) => {
				dispatch({ type: "SET_JOB_DATA", jobData:data });
			},
			setIsQC:  (data) => {
				dispatch({ type: "SET_IS_QC", isQC:data });
			},
			resetJob:  () => {
				window.localStorage.removeItem('jobsData');
				dispatch({ type: "RESET" });
			},
		}),
		[state]
	);

	return (
		<JobContext.Provider value={{ ...value }}>
			{props.children}
		</JobContext.Provider>
	);
};

export const useJob = () => {
	const context = React.useContext(JobContext);
	if (context === undefined) {
		throw new Error(`useJob must be used within a Provider`);
	}
	return context;
};

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dataLogo from "../../assets/img/dataBaeLogo.png";
import ToggleSlider from "../Toggle";
import { useJob } from "../../context/job.context";
import logout from "../../assets/img/logout.png";
import { useAuth } from "../../context/auth.context";

const Header = () => {
    const navigate = useNavigate();
    const { resetUser } = useAuth();
    const { isQC, setIsQC } = useJob();
    const { pathname } = useLocation();

    return (
        <div className="head-wrap">
            <img
                src={dataLogo}
                alt="Logo"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
            />
            <div
                style={{
                    color: "white",
                    flexDirection: "row",
                    display: pathname == "/login" ? "none" : "flex",
                    alignItems: "center",
                }}
            >
                Is QC
                <ToggleSlider
                    checked={isQC}
                    setChecked={(v) => {
                        navigate("/");
                        setIsQC(v);
                    }}
                />
                <img
                    src={logout}
                    alt="logout"
                    style={{ width: 30, height: 30, color: "white", cursor: "pointer", display: pathname == "/login" ? "none" : "flex", }}
                    onClick={() => resetUser()}
                />
            </div>
        </div>
    );
};

export default Header;

import React from "react";
import { useLocalStorage } from "../utils/helper";

const initialState = {
	user: undefined,
	token: undefined,
};

export const AuthContext = React.createContext();

const AuthReducer = (prevState, action) => {
	switch (action.type) {
		case "SET_USER":
			return {
				user: action.user,
				token: action.token,
			};
		case "RESET":
			return {
				user: undefined,
				token: undefined,
			};
		default:
			return {
				...prevState,
			};
	}
};

export const AuthProvider = (props) => {
	const [authData, setAuthData] = useLocalStorage(
		"data",
		JSON.stringify(initialState)
	);

	const [state, dispatch] = React.useReducer(AuthReducer, JSON.parse(authData));
	React.useEffect(() => {
		setAuthData(JSON.stringify(state));
	}, [state, setAuthData]);

	const value = React.useMemo(
		() => ({
			...state,
			setUser:  (data) => {
				dispatch({ type: "SET_USER", user:data?.user, token:data?.token });
			},
			resetUser:  () => {
				window.localStorage.clear();
				dispatch({ type: "RESET" });
			},
		}),
		[state]
	);

	return (
		<AuthContext.Provider value={{ ...value }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a Provider`);
	}
	return context;
};

import React, { useState } from "react";
import { useAuth } from "../../context/auth.context";
import "./Login.css";
import Header from "../../Components/Header";
import { login } from "../../services/apis";
import {toast} from "react-hot-toast";
import Loader from "../../Components/Loader";

function LoginForm() {
	const { setUser } = useAuth();
	const [email, setEmail] = useState("rishid@gmail.com");
	const [password, setPassword] = useState("pass123");
	const [loading, setLoading] = useState(false);

	const handleLogin = async () => {
		try {
			setLoading(true)
			const response = await login({ email, password });
			setUser(response);
			toast.success("Logged In Sussessfully!!")
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.error("Login failed:", error);
			toast.error(error?.message)
		}
	};

	return (
		<div className="container">
			<Header />
			<Loader visible={loading}/>
			<div className="login-container">
				<form>
					<div className="form-group">
						<label htmlFor="username">Username</label>
						<input
							type="text"
							id="username"
							className="form-control"
							placeholder="Enter your username"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="password">Password</label>
						<input
							type="password"
							id="password"
							className="form-control"
							placeholder="Enter your password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<button
						type="button"
						className="btn btn-primary"
						onClick={handleLogin}
					>
						Login
					</button>
				</form>
			</div>
		</div>
	);
}

export default LoginForm;

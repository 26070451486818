import { baseURL } from "../utils/constants";

const get = (url, token) => {
    return new Promise((resolve, reject) => {
        // console.log(token, '=============================================', baseURL + url);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        token && myHeaders.append("Authorization", `Bearer ${token}`);

        fetch(baseURL + url, {
            method: 'GET',
            headers: myHeaders,
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data, '-=----api response')
                if (data?.error) {
                    reject(new Error(data?.error))
                } else if (!data?.success) {
                    reject(new Error(data?.message))
                } else {
                    resolve(data);
                }
            })
            .catch((error) => {
                console.error(error, 'err');
                reject(error);
            });
    });
};
const post = (url, data, token, method = 'POST') => {
    return new Promise((resolve, reject) => {
        // console.log(data, token, '======================', method, '=======================', baseURL + url);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json');
        token && myHeaders.append("Authorization", `Bearer ${token}`);

        fetch(baseURL + url, {
            method: method,
            headers: myHeaders,
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data, '-=----api response')
                if (!data?.success) {
                    reject(new Error(data?.message))
                } else if (data?.error) {
                    reject(new Error(data?.error))
                } else {
                    resolve(data);
                }
            })
            .catch((error) => {
                console.error(error, 'err');
                reject(error);
            });
    });
};

export default {
    get,
    post,
    delete: (url, data, token) => post(url, data, token, 'DELETE'),
    patch: (url, data, token) => post(url, data, token, 'PATCH'),
};

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth.context";
import { toast } from "react-hot-toast";
import { getAllJob } from "../../services/apis";
import Loader from "../../Components/Loader";
import { staticData } from "../../utils/data";
import { useJob } from "../../context/job.context";

const Home = () => {
    const { token, user } = useAuth();
    const { isQC } = useJob();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getAllJob(user?._id, token);
                setData(response?.copyuser?.joblist ?? []);
                setLoading(false);
            } catch (error) {
                console.error("Error", error);
                toast.error(error?.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [isQC]);
    let arrData = Array.isArray(data)
        ? data.filter(
            (obj) =>
                obj?.status != "Completed" && (isQC
                    ? Array.isArray(obj?.QcjobId) && obj.QcjobId.length > 0
                    : Array.isArray(obj?.MainjobId) && obj.MainjobId.length > 0)
        )
        : [];
    // obj?.status == "Completed" &&
    //? obj?.isQC : !obj?.isQC
    return (
        <div className="home-wrap">
            <Header />
            <Loader visible={loading} />
            <div className="container-fluid">
                <div className="main page-wrap">
                    <div className="row">
                        <div className="start-card">
                            <div className="row row-box">
                                {arrData.length ? (
                                    arrData.map((obj, index) => (
                                        <div
                                            key={index}
                                            className="col-12 col-md-3"
                                            onClick={() =>
                                                navigate("/instructions", { state: { data: obj } })
                                            }
                                        >
                                            <div className="card card-body">
                                                <img
                                                    src={obj?.uploads[0]?.thumbnail?.url}
                                                    alt="image"
                                                    className="img-thumbnail"
                                                />
                                                <div style={{ marginTop: 10 }}>
                                                    {obj?.jobDetails?.jobTitle}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ color: "white", textAlign: "center" }}>{`No ${isQC ? "QC " : ""
                                        }Job Assigned`}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

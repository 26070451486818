import React from "react";
import "./style.css"; // Import your CSS file

const Loader = ({ visible = false }) => {
	return visible ? (
		<div className="loader-container">
			<div className="loader"></div>
		</div>
	) : null;
};

export default Loader;

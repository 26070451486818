import React, { useState, useEffect, useCallback, useRef } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import zoomIn from "../../assets/img/zoom-in.png";
import fit from "../../assets/img/fit.png";
import zoomOut from "../../assets/img/zoom-out.png";
import rectIcon from "../../assets/img/rectangle.png";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import { useJob } from "../../context/job.context";
import toast from "react-hot-toast";
import {
    changeJobStatus,
    patchBoundingboxSolutionByJobId,
    patchQCBoundingboxSolutionByJobId,
    patchQCEditBoundingboxSolutionByJobId,
} from "../../services/apis";
import { useAuth } from "../../context/auth.context";
import { useNavigate } from "react-router-dom";

const useGesture = createUseGesture([dragAction, pinchAction]);

const ShapeRectangle = () => {
    const navigate = useNavigate();
    const { token, user } = useAuth();
    const { jobData, isQC } = useJob();
    const { editor, onReady } = useFabricJSEditor();
    const [editorReady, setEditorReady] = useState(false);
    const imageUrls = jobData?.uploads[0]?.mainFile;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [annotationsByImage, setAnnotationsByImage] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activeObject, setActiveObject] = useState(null);
    const [status, setStatus] = useState("");
    const imageOffset = useRef([0, 0]);
    const [scale, setScale] = useState(1)

    const isQCofQC =
        Array.isArray(jobData?.QcjobId) && jobData.QcjobId.length > 0;

    useEffect(() => {
        if (editorReady) {
            console.count("--------------", imageUrls[currentImageIndex]?.url);
            let data = localStorage.getItem("rect");
            setAnnotationsByImage([]);
            setStatus(undefined);
            setActiveObject(undefined);
            editor.canvas
                .getObjects("rect")
                .map((rect) => editor.canvas.remove(rect));
            if (data) {
                data = JSON.parse(data);
                let currentRect = data.filter(
                    (obj) => obj?.url == imageUrls[currentImageIndex]?.url
                );
                currentRect.map((r) => {
                    addRect(r);
                });
            }
            if (imageUrls[currentImageIndex]?.answer) {
                if (typeof imageUrls[currentImageIndex]?.answer[0] == "string" && isQC)
                    setStatus(imageUrls[currentImageIndex]?.answer[0]);
                imageUrls[currentImageIndex]?.answer.map((rect) => {
                    if (
                        Array.isArray(data) &&
                        data.length > 0 &&
                        data.some(
                            (local_rect) =>
                                local_rect.url == imageUrls[currentImageIndex]?.url
                        )
                    ) {
                    } else if (rect?.bbox) {
                        addRect({
                            url: imageUrls[currentImageIndex]?.url,
                            left: rect?.bbox[0],
                            top: rect?.bbox[1],
                            width: rect?.bbox[2],
                            height: rect?.bbox[3],
                        });
                    }
                });
            }
        }
    }, [imageUrls, currentImageIndex, editorReady]);

    const addRect = function (data) {
        if (data?.url == imageUrls[currentImageIndex]?.url) {
            let selectionRect = new fabric.Rect({
                left: data?.left ?? data?.aCoords?.tl?.x,
                top: data?.top ?? data?.aCoords?.tl?.y,
                width: data?.width ?? data?.aCoords?.tr?.x - data?.aCoords?.tl?.x - 2,
                height: data?.height ?? data?.aCoords?.bl?.y - data?.aCoords?.tl?.y - 2,
                fill: "transparent",
                stroke: "white",
                strokeWidth: 2,
                strokeUniform: true,
                selectable: true,
                lockRotation: true,
                url: data?.url,
            });
            editor.canvas.add(selectionRect);
            setTimeout(() => {
                handleUpdate();
            }, 100);
        }
    };
    const handleUpdate = () => {
        const rects = editor.canvas.getObjects("rect");
        if (rects) {
            let data = [];
            rects.forEach((r) => {
                data.push({ url: r?.url, aCoords: r.aCoords });
            });
            let oldData = localStorage.getItem("rect");
            if (oldData) {
                oldData = JSON.parse(oldData);
                let otherRect = oldData.filter(
                    (obj) => obj?.url != imageUrls[currentImageIndex]?.url
                );
                localStorage.setItem("rect", JSON.stringify([...data, ...otherRect]));
            } else localStorage.setItem("rect", JSON.stringify(data));
            setAnnotationsByImage(
                data.filter((r) => r?.url == imageUrls[currentImageIndex]?.url)
            );
        }
    };

    const loadImage = () => {
        if (editorReady && imageUrls.length > 0) {
            const url = imageUrls[currentImageIndex]?.url;
            setLoading(true);
            try {
                fabric.Image.fromURL(
                    url,
                    (img) => {
                        setLoading(true);
                        const canvasWidth = img.width;
                        const canvasHeight = img.height;
                        const screenWidth = window.innerWidth;
                        const screenHeight = window.innerHeight;
                        const widthScale = screenWidth / canvasWidth;
                        const heightScale = screenHeight / canvasHeight;
                        const minScale = Math.min(
                            widthScale,
                            heightScale,
                            widthScale > heightScale ? 1.8 : 1
                        );
                        editor.canvas.setWidth(canvasWidth * minScale);
                        editor.canvas.setHeight(canvasHeight * minScale);
                        editor.canvas.setZoom(minScale);
                        img.set({
                            selectable: true,
                        });

                        editor.canvas.setBackgroundImage(
                            img,
                            editor.canvas.renderAll.bind(editor.canvas)
                        );
                        setScale(minScale)
                        setLoading(false);
                    },
                    {
                        crossOrigin: "anonymous",
                    }
                );
            } catch (error) {
                loadImage();
            }
        }
    };

    useEffect(() => {
        loadImage();
        if (editorReady) {
            editor.canvas.on("object:modified", handleUpdate);
            return () => {
                editor.canvas.off("object:modified", handleUpdate);
            };
        }
    }, [currentImageIndex, editorReady]);

    const handleAddRectangle = useCallback(() => {
        if (editorReady) {
            setIsDrawing(true);
            editor.canvas.defaultCursor = isDrawing ? "default" : "crosshair";
            editor.canvas.selection = !isDrawing;
            editor.canvas.selectionFullyContained = !isDrawing;
        }
    }, [isDrawing, editor]);

    const handleDeleteRectangle = useCallback(() => {
        if (editorReady && activeObject) {
            const activeRect = editor.canvas.getActiveObject();
            editor.canvas.remove(activeRect);
            setActiveObject(false);
            editor.canvas.renderAll();
            handleUpdate();
        }
    }, [activeObject]);

    const handleSave = async () => {
        if (isQC && isQCofQC && !status) {
            return toast.error("Click Accept or Reject button!!");
        }
        if (
            ((!isQC || status != "Reject") && annotationsByImage.length == 0) ||
            annotationsByImage.some(
                (obj) => obj?.url != imageUrls[currentImageIndex]?.url
            )
        ) {
            return toast.error("Add at least one rectangle");
        }
        try {
            setLoading(true);

            const answers = annotationsByImage.map((annotation, index) => {
                return {
                    id: 1 + index,
                    image_id: currentImageIndex + 1,
                    category_id: 1,
                    segmentation: [],
                    area:
                        (annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x) *
                        (annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y),
                    bbox: [
                        annotation?.aCoords?.tl?.x,
                        annotation?.aCoords?.tl?.y,
                        annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x,
                        annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y,
                    ],
                    iscrowd: 0,
                    attributes: { occluded: false, rotation: 0.0 },
                };
            });
            if (isQC && isQCofQC) answers.unshift(status);
            if (isQC && isQCofQC && status == 'Reject') {
                answers.length = 0;
                answers.push(status)
            }
            let body = {
                solutions: [
                    { url: imageUrls[currentImageIndex]?.url, answer: answers },
                ],
            };
            let api =
                isQC && isQCofQC
                    ? patchQCEditBoundingboxSolutionByJobId
                    : jobData?.isQC
                        ? patchQCBoundingboxSolutionByJobId
                        : patchBoundingboxSolutionByJobId;
            const res = await api(
                isQCofQC ? jobData.QcjobId[0] : jobData?.MainjobId[0],
                body,
                token
            );
            toast.success(res?.message);
            if (imageUrls.length == currentImageIndex + 1) {
                await changeJobStatus(
                    {
                        userId: user?._id,
                        jobObjId: jobData?._id,
                        newStatus: "Completed",
                    },
                    token
                );
                toast.success("Yay Job Done!!");
                navigate("/");
            } else handleNextImage();
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePrevImage = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    };

    const onReadyCanvas = (e) => {
        onReady(e);
        setEditorReady(true);
    };

    const bind = useGesture({
        onDrag: ({ down, movement }) => {
            let react = editor.canvas.getActiveObject();
            if (!react && !isDrawing) {
                editor.canvas.absolutePan({
                    x: -imageOffset.current[0] - movement[0],
                    y: -imageOffset.current[1] - movement[1],
                });
                if (!down)
                    imageOffset.current = [
                        movement[0] + imageOffset.current[0],
                        movement[1] + imageOffset.current[1],
                    ];
            }
        },
        onPinch: ({ offset: [d], memo }) => {
            const newZoom = Math.max(
                d + (memo ? memo : editor.canvas.getZoom()) / 10
            );
            editor.canvas.setZoom(newZoom);
        },
    });

    const handleZoom = (scale) => {
        editor.canvas.setZoom(editor.canvas.getZoom() + scale);
    };
    const handleCenter = () => {
        editor.canvas.absolutePan({ x: 0, y: 0 });
        imageOffset.current = [0, 0];
        editor.canvas.setZoom(scale);
    };
    useEffect(() => {
        if (editorReady) {
            let selectionRect;
            let isMouseDown = false;
            let startX, startY;

            const mouseDownHandler = (event) => {
                if (!isDrawing) {
                    return;
                }
                isMouseDown = true;
                const pointer = editor.canvas.getPointer(event.e);
                startX = pointer.x;
                startY = pointer.y;
                selectionRect = new fabric.Rect({
                    left: startX,
                    top: startY,
                    width: 1,
                    height: 1,
                    fill: "transparent",
                    stroke: "white",
                    strokeWidth: 2,
                    strokeUniform: true,
                    selectable: true,
                    url: imageUrls[currentImageIndex]?.url,
                });
                editor.canvas.add(selectionRect);
            };

            const mouseMoveHandler = (event) => {
                if (!isDrawing || !isMouseDown) return;

                const pointer = editor.canvas.getPointer(event.e);
                const currentX = pointer.x;
                const currentY = pointer.y;

                const left = Math.min(startX, currentX);
                const top = Math.min(startY, currentY);
                const width = Math.abs(startX - currentX);
                const height = Math.abs(startY - currentY);

                selectionRect.set({
                    left: left,
                    top: top,
                    width: width,
                    height: height,
                });
                selectionRect.setCoords();
                editor.canvas.renderAll();
            };

            const mouseUpHandler = (event) => {
                const rects = editor.canvas.getObjects("rect");
                rects.map((o) => o.set({ stroke: "white" }));
                const activeRect = editor.canvas.getActiveObject("rect");
                if (activeRect && activeRect.type === "rect") {
                    activeRect.set({ stroke: "red" });
                    setActiveObject(true);
                } else {
                    setActiveObject(false);
                }
                editor.canvas.renderAll();
                if (isDrawing) {
                    isMouseDown = false;
                    editor.canvas.defaultCursor = "default";
                    editor.canvas.selection = false;
                    setTimeout(() => {
                        handleUpdate();
                    }, 1000);
                    setIsDrawing(false);
                }
            };

            editor.canvas.on("mouse:down", mouseDownHandler);
            editor.canvas.on("mouse:move", mouseMoveHandler);
            editor.canvas.on("mouse:up", mouseUpHandler);

            return () => {
                editor.canvas.off("mouse:down", mouseDownHandler);
                editor.canvas.off("mouse:move", mouseMoveHandler);
                editor.canvas.off("mouse:up", mouseUpHandler);
            };
        }
    }, [currentImageIndex, isDrawing, editorReady]);

    return (
        <div>
            <Header />
            <Loader visible={loading} />
            <div className="content-wrap label-head label-txt">
                <h5 className="label-txt">Task: {jobData?.taskPrompt?.taskPrompt}</h5>
            </div>
            <div className="row">
                <div className="col-12 col-md-9">
                    <div className="choose-wrap">
                        <div
                            className="img-box"
                            style={{ position: "relative", overflowX: "scroll" }}
                            {...bind()}
                        >
                            <FabricJSCanvas className="canvas" onReady={onReadyCanvas} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 pos-rel">
                    <div className="action-box">
                        {isQC && isQCofQC ? (
                            <div className="d-flex">
                                <button
                                    className={`btn prim-btn prev-btn ${status == "Accept" ? "accept" : ""
                                        }`}
                                    style={{ backgroundColor: status == "Accept" ? "green" : "" }}
                                    onClick={() => setStatus("Accept")}
                                >
                                    Accept
                                </button>
                                <button
                                    className={`btn prim-btn next-btn ${status == "Reject" ? "reject" : ""
                                        }`}
                                    style={{ backgroundColor: status == "Reject" ? "red" : "" }}
                                    onClick={() => setStatus("Reject")}
                                >
                                    Reject
                                </button>
                            </div>
                        ) : null}
                        <div className="d-flex">
                            <button
                                className="btn prim-btn prev-btn"
                                onClick={handlePrevImage}
                                disabled={currentImageIndex == 0}
                            >
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>
                            <button
                                className="btn prim-btn next-btn"
                                onClick={handleNextImage}
                                disabled={imageUrls.length == currentImageIndex + 1}
                            >
                                <i className="fa-solid fa-chevron-right"></i>
                            </button>
                        </div>
                        <div className="d-flex">
                            <button className="btn prim-btn" onClick={() => handleZoom(0.1)}>
                                <img src={zoomIn} alt="icon" />
                            </button>
                            <button className="btn prim-btn" onClick={() => handleCenter()}>
                                <img src={fit} alt="icon" />
                            </button>
                            <button className="btn prim-btn" onClick={() => handleZoom(-0.1)}>
                                <img src={zoomOut} alt="icon" />
                            </button>
                        </div>
                        <button className="btn prim-btn" onClick={handleAddRectangle}>
                            <img src={rectIcon} alt="icon" />
                            Add Rectangle
                        </button>
                        {activeObject ? (
                            <button
                                className="btn prim-btn"
                                style={{ backgroundColor: "red" }}
                                onClick={handleDeleteRectangle}
                            >
                                <img src={rectIcon} alt="icon" />
                                Delete Rectangle
                            </button>
                        ) : null}
                        <button className="btn prim-btn" onClick={handleSave}>
                            {imageUrls.length == currentImageIndex + 1 ? "Submit" : "Save"}
                        </button>
                        <ul className="coords-list">
                            {annotationsByImage?.map((annotation, index) => (
                                <li key={index} className="coords">
                                    <strong>Coordinates {index + 1}:</strong>
                                    <ul>
                                        <li>X: {annotation?.aCoords?.tl?.x}</li>
                                        <li>Y: {annotation?.aCoords?.tl?.y}</li>
                                        <li>
                                            Width:{" "}
                                            {annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x}
                                        </li>
                                        <li>
                                            Height:{" "}
                                            {annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y}
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShapeRectangle;

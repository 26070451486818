import './assets/css/style.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from './Pages/Home';
import LoginForm from './Pages/Login/Login';
import { useAuth } from './context/auth.context';
import Instructions from './Pages/Instructions';
import ShapeRectangle from './Pages/Rectangle';
import ShapePolygon from './Pages/Polygone';

function App() {
  const { token } = useAuth();

  return (
    <Router>
      <div className='page-wrap'>
        <Routes>
          {token ? (
            <>
              <Route
                path="/login"
                element={<Navigate to={"/"} />}
              />
              <Route path="/" element={<Home />} />
              <Route path="/instructions" element={<Instructions />} />
              <Route path="/shapepolygon" element={<ShapePolygon />} />
              <Route path="/shaperectangle" element={<ShapeRectangle />} />
            </>
          ) : (
            <>
              <Route
                path="*"
                element={<Navigate to={token ? "/home" : "/login"} />}
              />
              <Route path="/login" element={<LoginForm />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

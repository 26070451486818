import React, { useState, useEffect, useCallback, useRef } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import { useNavigate } from "react-router-dom";
import fit from "../../assets/img/fit.png";
import zoomIn from "../../assets/img/zoom-in.png";
import zoomOut from "../../assets/img/zoom-out.png";
import polygonPoint from "../../assets/img/point.png";
import polygonIcon from "../../assets/img/polygon.png";

import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import { useJob } from "../../context/job.context";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import toast from "react-hot-toast";
import {
    changeJobStatus,
    patchPolygonSolutionByJobId,
    patchQCEditPolygonSolutionByJobId,
    patchQCPolygonSolutionByJobId,
} from "../../services/apis";
import { useAuth } from "../../context/auth.context";

const useGesture = createUseGesture([dragAction, pinchAction]);

function ShapePolygon() {
    const navigate = useNavigate();
    const { token, user } = useAuth();
    const { jobData, isQC } = useJob();
    const { editor, onReady } = useFabricJSEditor();
    const [editorReady, setEditorReady] = useState(false);
    const [annotationsByImage, setAnnotationsByImage] = useState([]);
    const [startDrawingPolygon, setStartDrawingPolygon] = useState(false);
    const [circleCount, setCircleCount] = useState(1);
    const [points, setPoints] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [activeObject, setActiveObject] = useState(false);
    const [status, setStatus] = useState("");
    const imageOffset = useRef([0, 0]);
    const [scale, setScale] = useState(1)

    const images = jobData?.uploads[0]?.mainFile;
    const isQCofQC =
        Array.isArray(jobData?.QcjobId) && jobData.QcjobId.length > 0;

    const Addpolygon = useCallback(() => {
        if (editorReady) {
            const activePolygon = editor.canvas.getActiveObject();
            if (activePolygon && activePolygon.type === "polygon") {
                activePolygon.set({ stroke: "white" });
                editor.canvas.renderAll();
                setActiveObject(false);
            }
            setStartDrawingPolygon(true);
            editor.canvas.defaultCursor = startDrawingPolygon
                ? "default"
                : "crosshair";
            editor.canvas.selection = !startDrawingPolygon;
        }
    }, [startDrawingPolygon, editor]);

    useEffect(() => {
        if (editorReady) {
            let data = localStorage.getItem("polygon");
            setAnnotationsByImage([]);
            setStatus(undefined);
            setActiveObject(false);
            editor.canvas
                .getObjects("polygon")
                .map((polygon) => editor.canvas.remove(polygon));
            if (data) {
                data = JSON.parse(data);
                let currentPolygon = data.filter(
                    (obj) => obj?.url == images[currentImageIndex]?.url
                );
                currentPolygon.map((r) => addPolygon(r));
            }
            if (images[currentImageIndex]?.answer) {
                if (typeof images[currentImageIndex]?.answer[0] == "string" && isQC)
                    setStatus(images[currentImageIndex]?.answer[0]);
                images[currentImageIndex]?.answer.map((polygon) => {
                    if (
                        Array.isArray(data) &&
                        data.length > 0 &&
                        data.some(
                            (local_polygon) =>
                                local_polygon.url == images[currentImageIndex]?.url
                        )
                    ) {
                    } else {
                        if (polygon?.segmentation && polygon?.bbox) {
                            let points = [];
                            for (let i = 0; i < polygon.segmentation.length; i += 2) {
                                let pair = {
                                    x: polygon.segmentation[i],
                                    y: polygon.segmentation[i + 1],
                                };
                                points.push(pair);
                            }
                            addPolygon({
                                url: images[currentImageIndex]?.url,
                                left: polygon?.bbox[0],
                                top: polygon?.bbox[1],
                                width: polygon?.bbox[2],
                                height: polygon?.bbox[3],
                                segmentation: points,
                                id: polygon?.id,
                            });
                        }
                    }
                });
            }
        }
    }, [currentImageIndex, editorReady]);

    const addPolygon = function (data) {
        if (data?.url == images[currentImageIndex]?.url) {
            const actualPoints =
                data?.segmentation ??
                data.points.map((point) => ({
                    x: point.x,
                    y: point.y,
                }));
            const polygon = new fabric.Polygon(actualPoints, {
                left: data?.left ?? data?.aCoords?.tl?.x,
                top: data?.top ?? data?.aCoords?.tl?.y,
                width: data?.width ?? data?.aCoords?.tr?.x - data?.aCoords?.tl?.x - 5,
                height: data?.height ?? data?.aCoords?.bl?.y - data?.aCoords?.tl?.y - 5,
                fill: "rgba(0, 0, 0, 0.2)", // Set the fill color of the polygon
                stroke: "white", // Set the stroke color of the polygon
                strokeWidth: 2, // Set the stroke width of the polygon
                selectable: true, // Enable object selection
                evented: true, // Allow responding to events
                name: "Polygon",
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                hasControls: false,
                url: data?.url,
                id: data?.id,
            });
            editor.canvas
                .getObjects("line")
                .map((line) => editor.canvas.remove(line));
            editor.canvas
                .getObjects("circle")
                .map((circle) => editor.canvas.remove(circle));
            editor.canvas.add(polygon);
            setTimeout(() => {
                handleUpdate();
            }, 100);
        }
    };

    const connectDotsAndDrawPolygon = () => {
        if (points.length < 3) {
            toast.error("A polygon needs at least 3 points!");
            return;
        }
        const actualPoints = points.map((point) => ({
            x: point.left,
            y: point.top,
        }));
        const polygon = new fabric.Polygon(actualPoints, {
            fill: "rgba(0, 0, 0, 0.2)",
            stroke: "white",
            strokeWidth: 2,
            selectable: true,
            evented: true,
            name: "Polygon",
            url: images[currentImageIndex]?.url,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            hasControls: false,
        });
        editor.canvas.getObjects("line").map((line) => editor.canvas.remove(line));
        editor.canvas
            .getObjects("circle")
            .map((circle) => editor.canvas.remove(circle));
        editor.canvas.add(polygon);
        editor.canvas.renderAll();
        setTimeout(() => {
            handleUpdate();
        }, 100);
        renderAnnotationCoordinates();
        setStartDrawingPolygon(false);
        setPoints([]);
        setCircleCount(1);
    };

    const handleDeletePolygon = useCallback(() => {
        if (editorReady && activeObject) {
            const activePolygon = editor.canvas.getActiveObject();
            editor.canvas.remove(activePolygon);
            setActiveObject(false);
            editor.canvas.renderAll();
            handleUpdate();
        }
    }, [activeObject]);

    const renderAnnotationCoordinates = () => {
        const annotationList = document.getElementById("annotation-list");
        annotationList.innerHTML = points
            .map(
                (point, index) =>
                    `<li key=${index}>Point ${index + 1}: (${point.left}, ${point.top
                    })</li>`
            )
            .join("");
    };

    const bind = useGesture({
        onDrag: ({ down, movement }) => {
            let obj = editor.canvas.getActiveObject();
            if (!obj) {
                editor.canvas.absolutePan({
                    x: -imageOffset.current[0] - movement[0],
                    y: -imageOffset.current[1] - movement[1],
                });
                if (!down)
                    imageOffset.current = [
                        movement[0] + imageOffset.current[0],
                        movement[1] + imageOffset.current[1],
                    ];
            }
        },
        onPinch: ({ offset: [d], memo }) => {
            const newZoom = Math.max(
                d + (memo ? memo : editor.canvas.getZoom()) / 10
            );
            editor.canvas.setZoom(newZoom);
        },
    });

    const handleZoom = (scale) => {
        setStartDrawingPolygon(false);
        editor.canvas.setZoom(editor.canvas.getZoom() + scale);
    };

    const handleCenter = () => {
        editor.canvas.absolutePan({ x: 0, y: 0 });
        imageOffset.current = [0, 0];
        editor.canvas.setZoom(scale);
    };

    useEffect(() => {
        if (editorReady) {
            let activeObj = null;
            const mouseDownHandler = function (option) {
                if (option.target && option.target?.name === "draggableCircle") {
                    let circles = editor.canvas.getObjects("circle");
                    setPoints(circles.map((v) => v));
                    return;
                } else {
                    if (startDrawingPolygon) {
                        const pointer = editor.canvas.getPointer(option.e);
                        const circle = new fabric.Circle({
                            left: pointer.x,
                            top: pointer.y,
                            radius: 5,
                            hasBorders: false,
                            hasControls: false,
                            name: "draggableCircle",
                            circleNo: circleCount,
                            fill: "white",
                            hasRotatingPoint: false,
                            originX: "center",
                            originY: "center",
                        });

                        // Store the circle in the points array
                        setPoints((prevPoints) => [...prevPoints, circle]);
                        editor.canvas.add(circle);
                        setCircleCount((prev) => prev + 1);
                        circle.on("mouse:over", function () {
                            circle.set({ stroke: "red", strokeWidth: 12 });
                            editor.canvas.requestRenderAll();
                        });

                        circle.on("mouse:out", function () {
                            circle.set({ stroke: null, strokeWidth: 0 });
                            editor.canvas.requestRenderAll();
                        });
                    } else {
                        if (activeObj) {
                            activeObj.set({ stroke: "white" });
                            editor.canvas.renderAll();
                        }
                        setActiveObject(false);
                        const activePolygon = editor.canvas.getActiveObject();
                        if (activePolygon && activePolygon.type === "polygon") {
                            activePolygon.set({ stroke: "red" });
                            editor.canvas.renderAll();
                            activeObj = activePolygon;
                            setActiveObject(true);
                        }
                        return;
                    }
                }
            };

            editor.canvas.on("mouse:down", mouseDownHandler);
            editor.canvas.on("mouse:up", mouseDownHandler);

            return () => {
                editor.canvas.off("mouse:down", mouseDownHandler);
                editor.canvas.off("mouse:up", mouseDownHandler);
            };
        }
    }, [currentImageIndex, startDrawingPolygon, editorReady]);

    const onReadyCanvas = (e) => {
        onReady(e);
        setEditorReady(true);
    };

    useEffect(() => {
        if (points.length > 1) {
            editor.canvas
                .getObjects("line")
                .map((line) => editor.canvas.remove(line));
            const newLines = [];
            for (let i = 1; i < points.length; i++) {
                const line = new fabric.Line(
                    [
                        points[i - 1].left,
                        points[i - 1].top,
                        points[i].left,
                        points[i].top,
                    ],
                    {
                        stroke: "white",
                        strokeWidth: 2,
                        selectable: false,
                        evented: false,
                    }
                );
                newLines.push(line);
            }
            editor.canvas.add(...newLines); // Add the lines to the canvas
            editor.canvas.renderAll(); // Force immediate re-render to show the lines immediately
        }
    }, [points]);

    const handleUpdate = () => {
        const polygon = editor.canvas.getObjects("polygon");
        if (polygon) {
            let data = [];
            polygon.forEach((r) => {
                data.push({ url: r?.url, points: r?.points, aCoords: r.aCoords });
            });
            let oldData = localStorage.getItem("polygon");
            if (oldData) {
                oldData = JSON.parse(oldData);
                let otherPolygon = oldData.filter(
                    (obj) => obj?.url != images[currentImageIndex]?.url
                );
                localStorage.setItem(
                    "polygon",
                    JSON.stringify([...otherPolygon, ...data])
                );
            } else localStorage.setItem("polygon", JSON.stringify(data));
            setAnnotationsByImage(
                data.filter((r) => r?.url == images[currentImageIndex]?.url)
            );
        }
    };

    const handleSave = async () => {
        if (isQC && isQCofQC && !status) {
            return toast.error("Click Accept or Reject button!!");
        }
        if (
            ((!isQC || status != "Reject") && annotationsByImage.length == 0) ||
            annotationsByImage.some(
                (obj) => obj?.url != images[currentImageIndex]?.url
            )
        ) {
            return toast.error("Add at least one shape");
        }
        try {
            setLoading(true);

            const answers = annotationsByImage.map((annotation, index) => {
                return {
                    id: 1 + index,
                    image_id: currentImageIndex + 1,
                    category_id: 1,
                    segmentation: annotation?.points.map(({ x, y }) => [x, y]).flat(),
                    area:
                        (annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x) *
                        (annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y),
                    bbox: [
                        annotation?.aCoords?.tl?.x,
                        annotation?.aCoords?.tl?.y,
                        annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x,
                        annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y,
                    ],
                    iscrowd: 0,
                    attributes: { occluded: false, rotation: 0.0 },
                };
            });
            if (isQC && isQCofQC) answers.unshift(status);
            if (isQC && isQCofQC && status == 'Reject') {
                answers.length = 0;
                answers.push(status)
            }
            let body = {
                solutions: [{ url: images[currentImageIndex]?.url, answer: answers }],
            };
            let api =
                isQC && isQCofQC
                    ? patchQCEditPolygonSolutionByJobId
                    : jobData?.isQC
                        ? patchQCPolygonSolutionByJobId
                        : patchPolygonSolutionByJobId;
            const res = await api(
                isQCofQC ? jobData.QcjobId[0] : jobData?.MainjobId[0],
                body,
                token
            );
            toast.success(res?.message);
            if (images.length == currentImageIndex + 1) {
                await changeJobStatus(
                    {
                        userId: user?._id,
                        jobObjId: jobData?._id,
                        newStatus: "Completed",
                    },
                    token
                );
                toast.success("Yay Job Done!!");
                navigate("/");
            } else handleNextImage();
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setLoading(false);
        }
    };
    const loadImage = () => {
        if (editorReady && images.length > 0) {
            const url = images[currentImageIndex]?.url;
            setLoading(true);
            fabric.Image.fromURL(
                url,
                (img) => {
                    setLoading(true);
                    const canvasWidth = img.width;
                    const canvasHeight = img.height;
                    const screenWidth = window.innerWidth;
                    const screenHeight = window.innerHeight;
                    const widthScale = screenWidth / canvasWidth;
                    const heightScale = screenHeight / canvasHeight;
                    const minScale = Math.min(
                        widthScale,
                        heightScale,
                        widthScale > heightScale ? 1.8 : 1
                    );
                    editor.canvas.setWidth(canvasWidth * minScale);
                    editor.canvas.setHeight(canvasHeight * minScale);
                    editor.canvas.setZoom(minScale);
                    editor.canvas.setBackgroundImage(
                        img,
                        editor.canvas.renderAll.bind(editor.canvas)
                    );
                    setScale(minScale)
                    setLoading(false);
                },
                { crossOrigin: "anonymous" }
            );
        }
    };

    useEffect(() => {
        loadImage();
        if (editorReady) {
            editor.canvas.on("object:modified", handleUpdate);
            return () => {
                editor.canvas.off("object:modified", handleUpdate);
            };
        }
    }, [currentImageIndex, editorReady]);

    const handlePrevImage = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <div>
            <Header />
            <Loader visible={loading} />
            <div className="content-wrap label-head label-txt">
                <h5 className="label-txt">Task: {jobData?.taskPrompt?.taskPrompt}</h5>
            </div>
            <div className="row">
                <div className="col-12 col-md-9">
                    <div className="choose-wrap">
                        <div
                            className="img-box"
                            style={{ position: "relative" }}
                            {...bind()}
                        >
                            <FabricJSCanvas className="canvas" onReady={onReadyCanvas} />
                        </div>
                        <div className="d-none">
                            <h2>Annotation Coordinates:</h2>
                            <ul id="annotation-list"></ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 pos-rel">
                    {/* <div className="back-btn">
                        <button onClick={() => navigate("/")} className="btn prim-btn">
                            Back to Start
                        </button>
                    </div> */}
                    <div className="action-box">
                        {isQC && isQCofQC ? (
                            <div className="d-flex">
                                <button
                                    className={`btn prim-btn prev-btn ${status == "Accept" ? "accept" : ""
                                        }`}
                                    style={{ backgroundColor: status == "Accept" ? "green" : "" }}
                                    onClick={() => setStatus("Accept")}
                                >
                                    Accept
                                </button>
                                <button
                                    className={`btn prim-btn next-btn ${status == "Reject" ? "reject" : ""
                                        }`}
                                    style={{ backgroundColor: status == "Reject" ? "red" : "" }}
                                    onClick={() => setStatus("Reject")}
                                >
                                    Reject
                                </button>
                            </div>
                        ) : null}
                        <div className="d-flex">
                            <button
                                className="btn prim-btn prev-btn"
                                onClick={handlePrevImage}
                                disabled={currentImageIndex == 0}
                            >
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>
                            <button
                                className="btn prim-btn next-btn"
                                onClick={handleNextImage}
                                disabled={images.length == currentImageIndex + 1}
                            >
                                <i className="fa-solid fa-chevron-right"></i>
                            </button>
                        </div>
                        <div className="d-flex">
                            <button className="btn prim-btn" onClick={() => handleZoom(0.1)}>
                                <img src={zoomIn} alt="icon" />
                            </button>
                            <button className="btn prim-btn" onClick={() => handleCenter()}>
                                <img src={fit} alt="icon" />
                            </button>
                            <button className="btn prim-btn" onClick={() => handleZoom(-0.1)}>
                                <img src={zoomOut} alt="icon" />
                            </button>
                        </div>
                        <div className="d-block">
                            <button
                                className="btn prim-btn"
                                type="button"
                                onClick={Addpolygon}
                            >
                                <img src={polygonPoint} alt="icon" />
                                Add Polygon Points
                            </button>
                            <button
                                className="btn prim-btn"
                                onClick={connectDotsAndDrawPolygon}
                            >
                                <img src={polygonIcon} alt="icon" />
                                <span>Create Polygon</span>
                            </button>
                            {activeObject ? (
                                <button
                                    className="btn prim-btn"
                                    style={{ backgroundColor: "red" }}
                                    onClick={handleDeletePolygon}
                                >
                                    <img src={polygonIcon} alt="icon" />
                                    Delete Polygon
                                </button>
                            ) : null}
                        </div>
                        <button onClick={handleSave} className="btn prim-btn">
                            Save
                        </button>
                    </div>
                    <ul className="coords-list">
                        {annotationsByImage?.map((annotation, index) => (
                            <li key={index} className="coords">
                                <strong>Coordinates {index + 1}:</strong>
                                <ul>
                                    <li>X: {annotation?.aCoords?.tl?.x}</li>
                                    <li>Y: {annotation?.aCoords?.tl?.y}</li>
                                    <li>
                                        Width:{" "}
                                        {annotation?.aCoords?.tr?.x - annotation?.aCoords?.tl?.x}
                                    </li>
                                    <li>
                                        Height:{" "}
                                        {annotation?.aCoords?.bl?.y - annotation?.aCoords?.tl?.y}
                                    </li>
                                    <li>
                                        Segments
                                        {Array.isArray(annotation?.points) &&
                                            annotation?.points.map((p, i) => (
                                                <p key={i}>
                                                    point {i + 1} -- {parseInt(p?.x)}, {parseInt(p?.y)}
                                                </p>
                                            ))}
                                    </li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <div className="content-wrap">
                        <div className="label-head">
                            <h5>Label</h5>
                        </div>
                        <div className="label-box">
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                            <p className="label-txt">
                                In publishing and graphic design, Lorem ipsum is a placeholder
                                text commonly used to demonstrate the visual
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShapePolygon;

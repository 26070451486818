import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import {
    getBoundingboxSolutionByJobId,
    getPolygonSolutionByJobId,
    getQCBoundingboxSolutionByJobId,
    getQCPolygonSolutionByJobId,
} from "../../services/apis";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth.context";
import { useJob } from "../../context/job.context";

const Instructions = () => {
    const { token } = useAuth();
    const { jobData, setJobData, isQC } = useJob();
    const navigate = useNavigate();
    const {
        state: { data },
    } = useLocation();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setJobData(data);
        const fetchData = async () => {
            try {
                setLoading(true);
                const api = data?.jobType?.selectedSubCategory.includes("boundingBox")
                    ? data?.isQC
                        ? getQCBoundingboxSolutionByJobId
                        : getBoundingboxSolutionByJobId
                    : data?.isQC
                        ? getQCPolygonSolutionByJobId
                        : getPolygonSolutionByJobId;
                // const api = data?.jobType?.selectedSubCategory.includes("boundingBox")
                //     ? isQC
                //         ? getQCBoundingboxSolutionByJobId
                //         : getBoundingboxSolutionByJobId
                //     : isQC
                //         ? getQCPolygonSolutionByJobId
                //         : getPolygonSolutionByJobId;

                const response = await api(
                    Array.isArray(data?.QcjobId) && data.QcjobId.length > 0
                        ? data.QcjobId[0]
                        : data?.MainjobId[0],
                    token
                );

                if (response?.success) {
                    const mergedData = response?.data.map((item) => {
                        const matchingFile = data?.uploads[0]?.mainFile.find(
                            (file) => file.url === item.url
                        );

                        if (matchingFile) {
                            return { ...item, ...matchingFile };
                        }
                        return item;
                    });

                    const filteredArray = mergedData.filter((item2) =>
                        isQC && Array.isArray(data?.QCFileId) && data.QCFileId.length > 0
                            ? data?.QCFileId.some((item3) => item3.QCfileId === item2._id)
                            : data?.normalFileId.some(
                                (item3) => item3.normalfileId === item2._id
                            )
                    );

                    setJobData({
                        ...data,
                        uploads: [{ ...data.uploads[0], mainFile: filteredArray }],
                    });
                }
                setLoading(false);
            } catch (error) {
                console.error("Login failed:", error);
                toast.error(error?.message);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="page-wrap">
            <Header />
            <Loader visible={loading} />
            <div className="container-fluid">
                <div className="content-wrap">
                    <div className="label-head">
                        <h5>Instructions</h5>
                    </div>
                    <img
                        className="instruction-img"
                        src={data?.uploads[0]?.thumbnail?.url}
                        alt="image"
                    />
                    <div className="label-box">
                        <p className="label-txt">{data?.jobDetails?.jobTitle}</p>
                        <p className="label-txt">
                            {data?.taskPrompt?.helpText ?? "Help Text"}
                        </p>
                    </div>
                    <div className="bot-btn">
                        <button
                            onClick={() => {
                                if (data?.jobType?.selectedSubCategory.includes("boundingBox"))
                                    return navigate("/shaperectangle");
                                if (data?.jobType?.selectedSubCategory.includes("imagePolygon"))
                                    return navigate("/shapepolygon");
                                toast.error("invalid job!!");
                            }}
                            className="btn prim-btn"
                            disabled={loading}
                        >
                            Let's Start
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instructions;
